const getTheme = (): string => {
	if ("theme" in localStorage) {
		return localStorage.theme;
	}

	if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
		return "dark";
	}

	return "light";
};

const isDarkMode = (): boolean => {
	if ("theme" in localStorage) {
		return localStorage.theme === "dark" ? true : false;
	}

	if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
		return true;
	}

	return false;
};

const setDarkMode = (darkMode: boolean, save = true) => {
	if (darkMode) {
		document.documentElement.classList.add("dark");
		if (save) localStorage.theme = "dark";
	} else {
		document.documentElement.classList.remove("dark");
		if (save) localStorage.theme = "light";
	}
	return darkMode;
};

export { isDarkMode, setDarkMode, getTheme };
