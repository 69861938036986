import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/home";
import Contact from "./pages/contact";
import Projects from "./pages/projects";
import NotFound from "./pages/notFound";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <Home />
	},
	{
		path: "/contact",
		element: <Contact />
	},
	{
		path: "/projects",
		element: <Projects />
	},
	{
		path: "*",
		element: <NotFound />
	}
]);
