import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { getTheme } from "./utils/theme";
import "./assets/base.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

/**
 * Apply the theme to the root element
 */
document.querySelector("html")?.classList.add(getTheme());

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
