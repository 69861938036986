import { useState } from "react";
import Moon from "../assets/svg/Moon";
import Sun from "../assets/svg/Sun";
import { getTheme, isDarkMode, setDarkMode } from "../utils/theme";

const ThemeButton = () => {
	const [theme, setTheme] = useState(getTheme());

	const toggleTheme = () => {
		setDarkMode(!isDarkMode());
		setTheme(isDarkMode() ? "dark" : "light");
	};

	return (
		<button
			className="anim cursor-pointer px-4 font-medium capitalize text-gray-400 duration-200 hover:scale-100 hover:text-gray-800 dark:hover:text-gray-200"
			onClick={toggleTheme}
		>
			{theme === "dark" ? <Sun /> : <Moon />}
		</button>
	);
};

export default ThemeButton;
