import ThemeButton from "./ThemeButton";
import { Link, useLocation, useNavigate } from "react-router-dom";

const menu = [
	{ name: "Anthony", href: "/", image: "home.png" },
	// { name: "About", href: "/about" },
	// { name: "Work", href: "/work" },
	{ name: "Projects", href: "/projects" },
	{ name: "Contact", href: "/contact" }
];

const Menu = (): JSX.Element => {
	const location = useLocation();
	const navigate = useNavigate();

	const isActive = (href: string): string => {
		return location.pathname === href
			? "text-gray-800 dark:text-gray-200 dark:hover:text-gray-200"
			: "text-gray-400 hover:text-gray-800 dark:hover:text-gray-200";
	};

	return (
		<header>
			<nav className="fixed flex h-20 w-full items-center justify-between sm:px-4 md:px-8 xl:px-16">
				<Link
					to={menu[0].href}
					className="font-logo text-dark text-5xl dark:text-white"
				>
					{menu[0].name}
				</Link>
				<ul className="hidden md:flex">
					{menu.slice(1, menu.length).map((item) => {
						return (
							<li>
								<Link
									to={item.href}
									className={
										isActive(item.href) +
										"anim cursor-pointer px-4 font-medium capitalize duration-200 hover:scale-100"
									}
								>
									{item.name}
								</Link>
							</li>
						);
					})}
					<li>
						<ThemeButton />
					</li>
				</ul>
			</nav>
		</header>
	);
};

export default Menu;
