import Menu from "../components/Menu";

const Home = () => {
	return (
		<div className="mt-5 bg-white dark:bg-black">
			<Menu />
		</div>
	);
};

export default Home;
